import { NumberField, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useShow, useTranslate } from "@refinedev/core";
import { Card, Typography, List } from "antd";
import React from "react";
import MapKitMap from "../../components/mapkitmap";
import { Show } from "../../components/crud/show";

const { Title } = Typography;

export const ShopsShow: React.FC<IResourceComponentsProps> = () => {
	const { queryResult } = useShow({});
	const { data, isLoading } = queryResult;

	const record = data?.data;
	const t = useTranslate();

	return (
		<Show isLoading={isLoading}>
			<div className="flex flex-1 flex-wrap md:flex-row">
				{/* Section de la carte */}
				<Card className="w-full md:w-1/2">
					{record?.location?.latitude && record?.location?.longitude && (
						<MapKitMap
							mapKitJSToken={import.meta.env.VITE_MAPS_API_KEY}
							latitude={record?.location?.latitude}
							longitude={record?.location?.longitude}
						/>
					)}
				</Card>

				{/* Section des informations principales */}
				<Card className="w-full md:w-1/2">
					<Title level={5}>{"ID"}</Title>
					<NumberField value={record?.id ?? ""} disabled />
					<Title level={5}>{t("shops.fields.name")}</Title>
					<TextField value={record?.name} />
					<Title level={5}>{t("shops.fields.description")}</Title>
					<TextField value={record?.description} />
					<Title level={5}>{t("shops.fields.location.city")}</Title>
					<TextField value={record?.location?.city} />
					<Title level={5}>{t("shops.fields.location.country")}</Title>
					<TextField value={record?.location?.country} />
					<Title level={5}>{t("shops.fields.location.address")}</Title>
					<TextField value={record?.location?.address} />
					<Title level={5}>{t("shops.fields.location.postalCode")}</Title>
					<TextField value={record?.location?.postalCode} />
					<Title level={5}>{t("shops.fields.reservationDelay")}</Title>
					<TextField value={record?.reservationDelay} />
				</Card>

				{/* Section des villes proches */}
				{record?.nearbyCities?.length > 0 && (
					<Card className="w-full">
						<Title level={5}>{t("shops.fields.nearbyCities")}</Title>
						<List
							dataSource={record?.nearbyCities}
							renderItem={(city) => (
								<List.Item>
									<div className="flex flex-col">
										<TextField value={`${(city as { location: { city: string; postalCode: string } })?.location?.city} (${(city as { location: { city: string; postalCode: string } })?.location?.postalCode})`} />
										<TextField value={(city as { location: { country: string } })?.location?.country} />
									</div>
								</List.Item>
							)}
						/>
					</Card>
				)}
			</div>
		</Show>
	);
};
