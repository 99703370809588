import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useList, useTranslate } from "@refinedev/core";
import { AutoComplete, Form, Input, List, Button } from "antd";
import React, { useCallback, useState } from "react";

export const ShopsCreate: React.FC<IResourceComponentsProps> = () => {
	const { formProps, saveButtonProps, onFinish } = useForm({});
	const [inputValue, setInputValue] = useState("");
	const [selectedLabel, setSelectedLabel] = useState<string>("");
	const [completeAddress, setCompleteAddress] = useState<string>("");
	const [nearbyCities, setNearbyCities] = useState<any[]>([]);
	const [citySearch, setCitySearch] = useState<string>("");

	const t = useTranslate();

	const debounce = (func: any, delay: number) => {
		let timer: any;
		return function (this: any, ...args: any) {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, delay);
		};
	};

	const debouncedSetInputValue = useCallback(debounce(setInputValue, 300), []);
	const debouncedSetCitySearch = useCallback(debounce(setCitySearch, 300), []);

	const { data: autoComplete } = useList({
		resource: "map/autocomplete",
		filters: [
			{
				field: "q",
				operator: "eq",
				value: inputValue,
			},
		],
	});

	const { data: cityAutoComplete } = useList({
		resource: "map/autocomplete",
		filters: [
			{
				field: "q",
				operator: "eq",
				value: citySearch,
			},
		],
	});

	const autoCompleteData = autoComplete?.data as any;
	const cityAutoCompleteData = cityAutoComplete?.data as any;

	const addNearbyCity = (city: { label: string; value: string }) => {
		if (!nearbyCities.some((c) => c.label === city.label)) {
			setNearbyCities([...nearbyCities, city]);
		}
	};

	const removeNearbyCity = (cityLabel: string) => {
		setNearbyCities(nearbyCities.filter((city) => city.label !== cityLabel));
	};

	const onFormFinish = (values: any) => {
		const { name, description, reservationDelay } = values;
		console.log("Form values", {
			name,
			description,
			completeAddress,
			nearbyCities: nearbyCities.map((city) => city.value),
			reservationDelay,
		});
		onFinish({
			name,
			description,
			completeAddress,
			nearbyCities: nearbyCities.map((city) => city.value),
			reservationDelay,
		});
	};

	return (
		<Create
			saveButtonProps={{
				...saveButtonProps,
				style: {
					backgroundColor: "#EDA0D8",
				},
			}}
		>
			<Form {...formProps} layout="vertical" onFinish={onFormFinish}>
				<Form.Item label={t("shops.fields.name")} name="name">
					<Input />
				</Form.Item>
				<Form.Item label={t("shops.fields.description")} name="description">
					<Input />
				</Form.Item>
				<Form.Item
					label={t("shops.fields.reservationDelay")}
					name="reservationDelay"
					tooltip={t("shops.fields.reservationDelay.tooltip")}>
					<Input
						type="number"
						min={0}
						placeholder={t("shops.fields.reservationDelay.placeholder")}
					/>
				</Form.Item>

				{/* AutoComplete pour l'adresse complète */}
				<Form.Item label={t("shops.fields.location.address")} name="completeAddress">
					<AutoComplete
						defaultValue={selectedLabel || ""}
						options={autoCompleteData?.results?.map((result: any) => ({
							label: result?.displayLines?.[0] + ", " + result?.displayLines?.[1],
							value: result?.completionUrl,
						}))}
						onSearch={(searchText) => debouncedSetInputValue(searchText)}
						onSelect={(value, option) => {
							formProps.form?.setFieldsValue({
								completeAddress: option?.label?.toString(),
							});
							setCompleteAddress(option?.value?.toString() ?? "");
							setSelectedLabel(option?.label?.toString() ?? "");
						}}
						value={selectedLabel}
						onChange={(_, option) => {
							if (!option) setSelectedLabel("");
						}}
					/>
				</Form.Item>

				{/* AutoComplete pour ajouter des villes proches */}
				<Form.Item label={t("shops.fields.nearbyCities")} name="nearbyCities">
					<AutoComplete
						defaultValue={citySearch || ""}
						options={cityAutoCompleteData?.results
							?.filter(
								(result: any) =>
									result?.structuredAddress?.locality &&
									result?.structuredAddress?.postCode &&
									result?.structuredAddress?.administrativeArea
							)
							?.reduce(
								(acc: any, result: any) => {
									const locality = result?.structuredAddress?.locality;
									const administrativeArea = result?.structuredAddress?.administrativeArea;
									const postCode = result?.structuredAddress?.postCode;
									if (!acc.localities.has(locality)) {
										acc.localities.add(locality);
										acc.filteredResults.push({
											label: `${locality}, ${administrativeArea}, ${postCode}`,
											value: result?.completionUrl,
										});
									}
									return acc;
								},
								{ localities: new Set(), filteredResults: [] }
							).filteredResults}
						onSearch={(searchText) => debouncedSetCitySearch(searchText)}
						onSelect={(value, option) => {
							formProps.form?.setFieldsValue({ nearbyCities: "" });
							if (option && option.label) {
								addNearbyCity({
									label: option.label.toString(),
									value: option.value?.toString() ?? "",
								});
							}
							setCitySearch("");
						}}
						value={citySearch}
						onChange={(value) => {
							setCitySearch(value);
						}}
					/>
				</Form.Item>

				{/* Affichage des villes proches ajoutées */}
				<Form.Item label={t("shops.fields.addedNearbyCities")}>
					<List
						dataSource={nearbyCities}
						renderItem={(city) => (
							<List.Item
								actions={[
									<Button danger onClick={() => removeNearbyCity(city.label)}>
										{t("shops.actions.remove")}
									</Button>,
								]}
							>
								{city.label}
							</List.Item>
						)}
					/>
				</Form.Item>
			</Form>
		</Create>
	);
};
